<template>
  <div>
    <c-header></c-header>
    <router-view />
    <c-character></c-character>
    <c-footer></c-footer>
    <back-top></back-top>
  </div>
</template>

<script>
import footer from './components/footer'
import header from './components/header'
import backTop from './components/backToTop'
import { cCharacter } from '../service/components'
export default {
  components: {
    'c-footer': footer,
    'c-header': header,
    cCharacter,
    backTop
  },
  data () {
    return {}
  },
  mounted () {}
}
</script>

<style lang="less" scoped></style>
